import React, { useState } from 'react';
import {
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement
} from '@chakra-ui/react'

export default function MindmegetteUrlForm({ callback, customOnClose }) {
    const [url, setUrl] = useState();

    const paste = async () => {
        setUrl(await navigator.clipboard.readText());
    };

    return (
        <>
            <form onSubmit={event => callback(event, url)}>
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Recept URL-je</FormLabel>
                        <InputGroup>
                            <Input data-testid="url" type="text" value={url} onChange={(event) => setUrl(event.target.value)} autoComplete='off' />
                            <InputRightElement width='6.25rem'>
                                <Button h='1.75rem' size='sm' onClick={paste}>Beillesztés</Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={customOnClose}>Mégse</Button>
                    <Button type="submit" bg='#548235' color='whitesmoke' ml={3}>Importálás</Button>
                </ModalFooter>
            </form>
        </>
    )
}
