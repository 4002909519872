import React, { useState } from 'react';
import {
	FormControl,
    FormLabel,
    Input,
    Text,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useDisclosure,
    ModalBody,
    ModalCloseButton
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig'
const { sha512 } = require('js-sha512');

export default function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errors, setErrors] = useState([]);

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleRegistration = (event) => {
        event.preventDefault();
        if (password === passwordConfirm && password?.length >= 8) {
            axios.post(apiConfig.webApi + 'user/register', { email: email, password: sha512(password)}, { timeout: 5000 })
                .then((response) => {
                    if (response.status === 200) {
                        setEmail('');
                        setPassword('');
                        setPasswordConfirm('');
                        setErrors([]);
                        console.log(response);
                        toast({ title: 'Sikeres regisztráció', status: 'success', duration: 2000, isClosable: true});
                        onClose();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        toast({ title: 'Érvénytelen regisztrációs adatok', status: 'error', duration: 2000, isClosable: true});
                    }
                    else if (error.response.status === 400){
                        let e = [];
                        for (let i in error.response.data.errors) {
                            let message = '';
                            if (error.response.data.errors[i].code === 'DuplicateUserName') {
                                message = 'Ilyen email címmel már regisztráltak'
                            }
                            else {
                                message = error.response.data.errors[i].description;
                            }
                            e = [...e, message];
                        }
                        setErrors(e);
                    }
                });
        }
        else if (password !== passwordConfirm) {
            toast({ title: 'Meg kell egyeznie a jelszó megerősítésének a jelszóval', status: 'error', duration: 2000, isClosable: true});
        }
        else if (password?.length < 8) {
            toast({ title: 'Minimum 8 karakter hosszú jelszót kell választanod', status: 'error', duration: 2000, isClosable: true});
        }
    };

    return (
        <>
            <Text fontSize='12' as='u' onClick={onOpen} className='cursor'>Nincs még fiókom</Text>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW='300px'>
                    <ModalHeader>Regisztráció</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleRegistration}>
                        <ModalBody mb='4'>
                            <FormControl mt='5' mb='1'>
                                <FormLabel>Email</FormLabel>
                                <Input type="email" onChange={(event) => setEmail(event.target.value)}  autoComplete='off' isRequired={true}/>
                            </FormControl>
                            {!!errors && errors.length > 0 && errors.map(item => <Text fontSize='12' color='red'>{item}</Text>)}
                            <FormControl mt='5'>
                                <FormLabel>Jelszó</FormLabel>
                                <Input type="password" onChange={(event) => setPassword(event.target.value)}  autoComplete='off' isRequired={true}/>
                            </FormControl>
                            <FormControl mt='5'>
                                <FormLabel>Jelszó megerősítése</FormLabel>
                                <Input type="password" onChange={(event) => setPasswordConfirm(event.target.value)}  autoComplete='off' isRequired={true}/>
                            </FormControl>

                            <FormControl mt='5'>
                                <Button type="submit">Regisztrálok</Button>
                            </FormControl>
                        </ModalBody>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};
