import React, { useState, useContext } from 'react';
import {
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Center,
    Text,
    VStack,
    useToast,
    Progress
} from '@chakra-ui/react'
import ProductSelector from '../products/productSelector';
import apiConfig from '../../config/apiconfig';
import axios from 'axios';
import { CheckIcon } from '@chakra-ui/icons';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function MindmegetteIngredients({ rawIngredients, compositeFoodId }) {
    const [instructions, setInstructions] = useState(true);
    const [ingredientIndex, setIngredientIndex] = useState(0);
    const [prodId, setProdId] = useState();
    const [prodName, setProdName] = useState('');
    const [qty, setQty] = useState('');
    const [created, setCreated] = useState(false);

    const toast = useToast();
    const { notify } = useContext(ErrorNotifierContext);

    const hop = () => {
        if (ingredientIndex < rawIngredients.length - 1) {
            setProdName(rawIngredients[ingredientIndex + 1].name);
            setProdId(rawIngredients[ingredientIndex + 1].id);
            setIngredientIndex(ingredientIndex + 1);
            setQty('');
            setCreated(false);
        }
        else {
            window.location.reload();
        }
    };

    const letsStart = () => {
        if (rawIngredients.length > 0) {
            setProdName(rawIngredients[0].name);
            setProdId(rawIngredients[0].id);
            setInstructions(false);
        }
        else {
            window.location.reload();
        }
    }

    const addProduct = () => {
        const networkCall = new Promise((resolve, reject) => {
            axios.post(apiConfig.webApi + 'products', { householdId: localStorage.getItem('householdId'), productName: prodName })
                .then((response) => {
                    setCreated(true);
                    setProdId(response.data.id);
                    resolve();
                })
                .catch((error) => {
                    notify(error, 'Sikertelen létrehozás');
                    reject();
                });
        });

        toast.promise(networkCall, {
            success: { title: 'Sikeres létrehozás' },
            error: { title: 'Hiba történt' },
            loading: { title: 'Létrehozás folyamatban' },
        });
    };

    const addCompositeItem = (event) => {
        event.preventDefault();
        if (!!prodId && !!qty) {
            const networkCall = new Promise((resolve, reject) => {
                axios.post(apiConfig.webApi + 'compositeitems', { productId: prodId, compositeFoodId: compositeFoodId, quantityPerPortion: qty })
                    .then((response) => {
                        hop();
                        resolve();
                    })
                    .catch((error) => {
                        notify(error, 'Sikertelen párosítás');
                        reject();
                    });
            });

            toast.promise(networkCall, {
                success: { title: 'Sikeres mentés' },
                error: { title: 'Hiba történt' },
                loading: { title: 'Mentés folyamatban' },
            });
        }
        else {
            toast({ title: 'Kérlek, minden kötelező mezőt helyesen tölts ki!', status: 'error', duration: 2000, isClosable: true });
        }
    };

    if (instructions) {
        return (
            <>
                <Center>
                    <VStack m='4' pl='4'>
                        <ul>
                            <li>
                                <Text>Minden hozzávalóhoz kérlek válaszd ki, hogy mely háztartásbeli termék feleltethető meg.</Text>
                            </li>
                            <li>
                                <Text>Ha nincs ilyen termék, akkor lesz lehetőséged létrehozni.</Text>
                            </li>
                            <li>
                                <Text>Ha nem szeretnél hozzá terméket megadni, akkor nyomj a kihagyom gombra.</Text>
                            </li>
                        </ul>
                        <Button onClick={letsStart} bg='#548235' color='whitesmoke' mt='4'>Kezdjük</Button>
                    </VStack>
                </Center>
            </>
        );
    }

    return (
        <>
            <form onSubmit={addCompositeItem}>
                <ModalBody pb={6}>
                    <Progress colorScheme='green' size='sm' mb='4' value={(ingredientIndex / rawIngredients.length) * 100} hasStripe />
                    <FormControl>
                        <FormLabel>{rawIngredients[ingredientIndex]?.description}:</FormLabel>
                        {!!created ? <Center>
                            <CheckIcon color='green' fontSize='xxx-large' my='4' />
                        </Center> : <>
                            <ProductSelector prodId={prodId} setProductId={setProdId} visible={true} title='' />
                            <Text align='center' my='4'>VAGY</Text>
                            <InputGroup>
                                <Input type="text" value={prodName} placeholder='Hozz létre új terméket' onChange={(event) => setProdName(event.target.value)} autoComplete='off' />
                                <InputRightElement width='6.25rem'>
                                    <Button onClick={addProduct} h='1.75rem' size='sm' colorScheme='green'>Létrehozás</Button>
                                </InputRightElement>
                            </InputGroup>
                        </>}
                    </FormControl>
                    <FormControl mt='4'>
                        <FormLabel>Mennyiség egy adaghoz:</FormLabel>
                        <InputGroup>
                            <Input type="number" step='0.01' textAlign='end' value={qty} onChange={(event) => setQty(event.target.value)} mb='4' autoComplete='off' />
                            <InputRightElement>db</InputRightElement>
                        </InputGroup>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={hop} colorScheme='blue'>Kihagyom</Button>
                    <Button type="submit" bg='#548235' color='whitesmoke' ml={3}>Mentés</Button>
                </ModalFooter>
            </form>
        </>
    )
}
