import React, { useState, useContext } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    ModalHeader,
    ModalCloseButton,
    IconButton,
    Icon,
    useToast,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import MindmegetteUrlForm from './mindmegetteUrlForm';
import MindmegetteRecipeForm from './mindmegetteRecipeForm';
import MindmegetteIngredients from './mindmegetteIngredients';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function MindmegetteModal() {
    const { isOpen, onOpen } = useDisclosure();

    const toast = useToast();
    const { notify } = useContext(ErrorNotifierContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [title, setTitle] = useState();
    const [recipe, setRecipe] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [id, setId] = useState();
    const [ingredients, setIngredients] = useState([]);

    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const submitUrl = (event, url) => {
        event.preventDefault();
        const networkCall = new Promise((resolve, reject) => {
            axios.post(apiConfig.webApi + 'compositefoods/mindmegette/scrape', { url: url, householdId: localStorage.getItem('householdId') })
                .then((response) => {
                    setTitle(response.data.title);
                    setRecipe(response.data.recipe);
                    setIngredients(response.data.ingredients);
                    setImageUrl(response.data.imageUrl);
                    setTabIndex(1);
                    resolve();
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezés');
                    reject();
                });
        });

        toast.promise(networkCall, {
            success: { title: 'Sikeres import' },
            error: { title: 'Hiba történt' },
            loading: { title: 'Importálás folyamatban' },
        });
    };

    const submitRecipe = (event) => {
        event.preventDefault();
        const networkCall = new Promise((resolve, reject) => {
            axios.post(apiConfig.webApi + 'compositefoods/mindmegette/add', { householdId: localStorage.getItem('householdId'), name: title, recipe: recipe, imagePath: imageUrl })
                .then((response) => {
                    setId(response.data.id);
                    setTabIndex(2);
                    resolve();
                })
                .catch((error) => {
                    notify(error, 'Sikertelen létrehozás');
                    reject();
                });
        });

        toast.promise(networkCall, {
            success: { title: 'Sikeres létrehozás' },
            error: { title: 'Hiba történt' },
            loading: { title: 'Létrehozás folyamatban' },
        });
    };

    const customOnClose = () => {
        window.location.reload();
    };

    return (
        <>
            <IconButton borderRadius="0" icon={<Icon viewBox='0 0 100 100' boxSize="40px" padding="0">
                <path
                    fill='#9aca3c'
                    d="M79.7 30.1c.5-.7.8-1.6.8-2.7v-2.9c0-1.1-.3-2-.8-2.6-.5-.7-1.2-1-2.1-1h-2.1v10.3h2.1c.8-.1 1.5-.4 2.1-1.1zm.6-20.1h-68v80h80V22a12.04 12.04 0 0 0-12-12zm-37.2 8h3.7v16h-3.7V18zm-22.8 0h4.9l3.6 11.2h.1L32.4 18h4.9v16h-3.7V23.7h-.1L30 34h-2.5L24 23.8h-.1V34h-3.7V18h.1zm10.5 50.8H24v3.4h5.6v2.9H24v4h6.8V82H20.3V66h10.5v2.8zm2.7-21.1L30 58h-2.5L24 47.8h-.1V58h-3.7V42h4.9l3.6 11.2h.1L32.4 42h4.9v16h-3.7l-.1-10.3zm15.6 21.1h-4.2V82h-3.7V68.8H37V66h12.1v2.8zm3-23.9h-6.8v3.4H51v2.9h-5.6v4h6.8V58H41.6V42h10.5v2.9zm.6-26.9h3.7l5.9 10.1h.1V18H66v16h-3.7l-5.9-10.1h-.1v10h-3.7V18h.1zm14.6 50.8H63V82h-3.7V68.8h-4.2V66h12.1v2.8h.1zm.9-13c-.6.7-1.3 1.2-2.4 1.7-1 .5-2.3.7-3.9.7-2 0-3.6-.6-4.8-1.8s-1.9-2.8-1.9-4.7v-3.3c0-1.9.6-3.5 1.8-4.7s2.8-1.8 4.7-1.8c2 0 3.6.5 4.6 1.4s1.5 2.2 1.5 3.7v.1h-3.5c0-.8-.2-1.4-.6-1.7-.4-.4-1-.6-1.9-.6s-1.6.3-2.1 1-.8 1.6-.8 2.7v3.4c0 1.1.3 2 .8 2.7s1.3 1 2.2 1c.7 0 1.2-.1 1.6-.2s.7-.3.9-.4v-2.9h-2.8v-2.5h6.5v6.2h.1zM71.7 18h5.9c1.9 0 3.5.6 4.7 1.8 1.3 1.2 1.9 2.8 1.9 4.7v2.9c0 1.9-.6 3.5-1.9 4.7s-2.8 1.8-4.7 1.8h-5.9V18zm12.5 50.8h-6.8v3.4H83v2.9h-5.6v4h6.8V82H73.7V66h10.5v2.8z"
                />
            </Icon>}
                onClick={onOpen} />

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={customOnClose}
            >
                <ModalOverlay />
                <ModalContent width="90%">
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <Tabs colorScheme='green' index={tabIndex}>
                        <TabList>
                            <Tab isDisabled={tabIndex !== 0}>URL</Tab>
                            <Tab isDisabled={tabIndex !== 1}>Recept</Tab>
                            <Tab isDisabled={tabIndex !== 2}>Hozzávalók</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel p='0'>
                                <MindmegetteUrlForm callback={submitUrl} customOnClose={customOnClose} />
                            </TabPanel>
                            <TabPanel p='0'>
                                <MindmegetteRecipeForm customOnClose={customOnClose} title={title} setTitle={setTitle} recipe={recipe} setRecipe={setRecipe} onSubmit={submitRecipe} />
                            </TabPanel>
                            <TabPanel p='0'>
                                <MindmegetteIngredients rawIngredients={ingredients} compositeFoodId={id} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalContent>
            </Modal>
        </>
    )
}
